import { useEffect, useState } from "react";
import { getNewsAPI } from "../../services/api/CustomOpacAssetsAPI";
import LoadingBox from "../../components/LoadingBox/LoadingBox";
import ImageWithFallback from "../../components/ImageWithFallback/ImageWithFallback";
import app_params from "../../config/params";
import "./NewScreen.css";

const NewScreen = () => {
  const [loaded, setLoaded] = useState(false);
  const [news, setNews] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      const response = await getNewsAPI();
      setLoaded(true);
      if (response?.success) setNews(response?.content);
    };
    if (!loaded) getNews();
  }, [loaded]);

  if (!loaded) {
    return (
      <section className="container">
        <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded text-center">
          <LoadingBox />
        </div>
      </section>
    );
  }

  if (loaded && news?.length < 1) {
    return (
      <section className="container">
        <div className="bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded text-center">
          <p>No hay noticias disponibles.</p>
        </div>
      </section>
    );
  }

  return (
    <section className="container mt-4">
      <div className="row bg-white p-lg-2 mb-3 shadow-sm rounded mb-3 text-center">
        <h1>Noticias</h1>
      </div>
      {news?.map((newItem, idx) => {
        const coverUrl = newItem?.cover
          ? newItem.cover
          : `${app_params.basepath}/assets/default-new-image.jpg`;
        return (
          <div key={idx} className="row bg-white p-lg-2 mb-3 shadow-sm rounded">
            <div className="col-12 col-lg-4">
              <div className="row text-lg-center cover_container">
                <a
                  href={coverUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="p-0"
                  style={{ margin: 0 }}
                >
                  <ImageWithFallback
                    fallback={`${app_params.basepath}/assets/default-new-image.jpg`}
                    src={newItem?.cover}
                    className={`figure-img img-fluid rounded border border-1 cover_thumb`}
                  />
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-8 mb-0">
              <div className="row">
                <div dangerouslySetInnerHTML={{ __html: newItem?.content }} />
              </div>
            </div>
            <p className="text-end fw-light fst-italic py-0 mb-0">
              {newItem?.created_at} - {newItem?.news_type}
            </p>
          </div>
        );
      })}
    </section>
  );
};

export default NewScreen;
