import React, { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./SearchResultItem.module.css";

import Axios from "../../services/Api";
import QuoteModal from "../QuoteModal/QuoteModal";
import app_params from "../../config/params";
import ShareModal from "../ShareModal/ShareModal";

const recordDownload = async (record, file) => {
  const { data } = await Axios.DownloadHit({
    record: record,
  });

  window.open(file, "_blank");
};

const SearchResultItem = (props) => {
  const {
    result: { result_header },
  } = useSelector((state) => state.searchResult);

  const [quotes, setQuotes] = useState({});

  const getQuotes = (event) => {
    event.preventDefault()
    props?.item?.id && Axios.GetQuotes({ urlParams: { id: props?.item?.id } })
        .then((res) => {
          if (res.data?.success && res.data?.success === "true") {
            setQuotes(res.data?.quotes);
          } else {
            setQuotes({});
          }
        })
        .catch((error) => {
          console.log(error);
          setQuotes({});
        });
  }

  return (
    <div
      className="col-12 mb-2 p-2 bg-white shadow-sm rounded"
      key={props.item?.id}
    >
      <div className="row">
        <div className="col-4 col-lg-2">
          <div className="row">
            <div className="col-12">
              <img
                src={
                  props.item?.thumbnail?.toLowerCase() !== "null"
                    ? props?.item?.thumbnail
                    : ""
                }
                className={`img-thumbnail rounded`}
                alt=""
              />
            </div>
            <div className="col-12 d-flex d-lg-none flex-column justify-content-center mt-3">
              {/* <button
                className="btn btn-sm btn-outline-secondary col-12 mb-2"
                id={`shareRecordButton-${props?.item?.id}`}
                onClick={() => shareRecord()}
              >
                <span>
                  <i className="fas fa-share-alt"></i> Compartir
                </span>
              </button> */}
              <button
                className="btn btn-sm btn-outline-secondary col-12 mb-2"
                data-bs-toggle="modal"
                data-bs-target={`#shareModal-${props?.item?.id}`}
              >
                <span>
                  <i className="fas fa-share-alt"></i> Compartir
                </span>
              </button>
              <button
                className="btn btn-sm btn-outline-secondary col-12"
                data-bs-toggle="modal"
                onClick={(event) => event.preventDefault()}
                data-bs-target={`#quoteModal-${props?.item?.id}`}
              >
                <span>
                  <i className="fas fa-quote-left"></i> Referencia
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-8 col-lg-8 d-flex flex-column justify-content-between">
          <div className="row mb-2">
            <div className="col-12">
              <a
                href={`${app_params.basepath}/record/${props.item?.id}?${result_header?.searchargs}${props?.searchargs}`}
                className={`text-decoration-none ${styles.item_title}`}
              >
                {props.item?.title}
              </a>
              <br />
              {props.item?.authors && (
                <React.Fragment>
                  {props.item?.authors}
                  <br />
                </React.Fragment>
              )}
              {props.item?.editorial && (
                <React.Fragment>
                  {props.item?.editorial}
                  <br />
                </React.Fragment>
              )}
              {props.item?.classification && (
                <React.Fragment>
                  {props.item?.classification}
                  <br />
                </React.Fragment>
              )}
              {props.item?.edicion && (
                <React.Fragment>
                  {props.item?.edicion}
                  <br />
                </React.Fragment>
              )}
              {props.item?.biblioteca && (
                <React.Fragment>
                  {props.item?.biblioteca}
                  <br />
                </React.Fragment>
              )}
            </div>
            <div className="col-12 col-lg-10">
              {props.item?.available === "true" && (
                <span className="badge roudend-pill bg-success">
                  <i className="fas fa-check"></i> Disponible
                </span>
              )}
              {props.item?.available === "false" && (
                <span className="badge roudend-pill bg-danger">
                  <i className="fas fa-times"></i> No disponible
                </span>
              )}
              <br />
              {props.item?.digital && (
                <button
                  className="btn btn-primary mt-2"
                  onClick={() => recordDownload(props.item?.id, props.item?.digital)}
                >
                  <span className="me-2">
                    <i className="fas fa-globe-americas"></i>
                  </span>
                  En línea
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-2 d-none d-lg-flex flex-column justify-content-start">
          {/* <button
            className="btn btn-sm btn-outline-secondary col-12 mb-2"
            id={`shareRecordButtonResponsive-${props?.item?.id}`}
            onClick={() => shareRecord()}
          >
            <span>
              <i className="fas fa-share-alt"></i> Compartir
            </span>
          </button> */}
          <button
            className="btn btn-sm btn-outline-secondary col-12 mb-2"
            data-bs-toggle="modal"
            data-bs-target={`#shareModal-${props?.item?.id}`}
          >
            <span>
              <i className="fas fa-share-alt"></i> Compartir
            </span>
          </button>
          <button
            className="btn btn-sm btn-outline-secondary col-12"
            onClick={getQuotes}
            data-bs-toggle="modal"
            data-bs-target={`#quoteModal-${props?.item?.id}`}
          >
            <span>
              <i className="fas fa-quote-left"></i> Referencia
            </span>
          </button>
        </div>
      </div>
      {/* <Toast
        buttonID={`shareRecordButton-${props?.item?.id}`}
        toastID={`shareRecordToast-${props?.item?.id}`}
        variant="secondary"
      >
        Enlace de título copiado en el portapapeles
      </Toast>
      <Toast
        buttonID={`shareRecordButtonResponsive-${props?.item?.id}`}
        toastID={`shareRecordToast-${props?.item?.id}`}
        variant="secondary"
      >
        Enlace de título copiado en el portapapeles
      </Toast> */}
      <QuoteModal
        modalID={`quoteModal-${props?.item?.id}`}
        item={props?.item}
        quotes={quotes}
      />
      <ShareModal id={`shareModal-${props?.item?.id}`} url={`${window.location.origin}${app_params.basepath}/record/${props.item?.id}?${encodeURI(result_header?.searchargs)}${encodeURI(props?.searchargs)}`} quote={props.item?.title} />
    </div>
  );
};

export default SearchResultItem;
